import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import tnbcSafetyTable1 from "../../../assets/TNBC-Advanced-355-discontinue-safety-small-V4.svg"
import tnbcSafetyTable2 from "../../../assets/TNBC-Advanced-355-interruption-safety-small-V4.svg"
import tnbcSafetyTable3 from "../../../assets/TNBC-Advanced-355-safety-small-V4.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-365 - Adverse Reactions & Safety Data`,
    keywords: `keynote 365, adverse reactions`,
    description: `Health care professionals can review adverse reactions with KEYTRUDA® (pembrolizumab) in patients with locally recurrent unresectable or metastatic triple-negative breast cancer (TNBC). See safety data from KEYNOTE-365.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/triple-negative-breast-cancer/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/triple-negative-breast-cancer/","@type":"MedicalWebPage","name":"Safety Data From KEYNOTE-355","description":"Fatal adverse reactions occurred in 2.5% of patients receiving KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo, including:"}`
    ]    
}

// image alts - so they do not repeat in mobile & desktop
const tnbcSafetyAlt1 = "Most Common Adverse Reactions Resulting in Permanent Discontinuation of KEYTRUDA® (pembrolizumab) (≥1%) in KEYNOTE-355"
const tnbcSafetyAlt2 = "Most Common Adverse Reactions Leading to Interruption of KEYTRUDA® (pembrolizumab) (≥2%) in KEYNOTE-355"
const tnbcSafetyAlt3 = "Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA® (pembrolizumab) With paclitaxel, nab-paclitaxel, or gem carbo in KEYNOTE-355"

//from /src/util/data/indications.js
const indicationId = 26;
const jobCode = jobCodes[26].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'High-Risk Early-Stage TNBC', url: '/efficacy/early-stage-triple-negative-breast-cancer/' },
            ]
        },
        {
            heading: "Additional Safety Data",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                    ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    { label: 'Selected Adverse Reactions in KEYNOTE&#8288;-&#8288;355' }, 
]

const safetyTable3Footnotes = [
    {
        label: "c.",
        text: "Graded per National Cancer Institute Common Terminology Criteria for Adverse Events (NCI CTCAE) v4.03."
    },
    {
        label: "d.",
        text: "Includes fatigue and asthenia."
    },
    {
        label: "e.",
        text: "Includes rash, rash maculopapular, rash pruritic, rash pustular, rash macular, rash papular, butterfly rash, rash erythematous, eyelid rash."
    },
    {
        label: "f.",
        text: "Includes cough, productive cough, upper-airway cough syndrome."
    },
    {
        label: "g.",
        text: "Includes headache, migraine, tension headache."
    }
];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock definitions="gem carbo = gemcitabine and carboplatin; nab-paclitaxel = paclitaxel protein⁠-⁠bound.">
                                <p><b>Fatal adverse reactions occurred in 2.5% of patients receiving KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo, including:</b></p>
                                <ul>
                                    <li>Cardio-respiratory arrest (0.7%)</li>
                                    <li>Septic shock (0.3%)</li>
                                </ul>
                                <p><b>Serious adverse reactions occurred in 30% of patients receiving KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo. Those occurring in ≥2% of patients were:</b></p>
                                <ul>
                                    <li>Pneumonia (2.9%)</li>
                                    <li>Anemia (2.2%)</li>
                                    <li>Thrombocytopenia (2%)</li>
                                </ul>
                                </TextBlock>
                                <DataTable 
                                    mobileImage={() => <img src={tnbcSafetyTable1} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={tnbcSafetyAlt1}/>}
                                    desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/TNBC-Advanced-355-discontinue-safety-large-V4.png" alt={tnbcSafetyAlt1}/>}
                                    title="KEYTRUDA was discontinued for adverse reactions in 11% of patients. The most common adverse reactions resulting in permanent discontinuation of KEYTRUDA (≥1%):"
                                />
                                <DataTable 
                                    mobileImage={() => <img src={tnbcSafetyTable2} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={tnbcSafetyAlt2}/>}
                                    desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/TNBC-Advanced-355-interruption-safety-large-V4.png" alt={tnbcSafetyAlt2}/>}
                                    title="Adverse reactions leading to the interruption of KEYTRUDA occurred in 50% of patients (n=596). The most common adverse reactions leading to interruption of KEYTRUDA (≥2%):" 
                                />

                                <DataTable 
                                    mobileImage={() => <img src={tnbcSafetyTable3} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={tnbcSafetyAlt3}/>}
                                    desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/TNBC-Advanced-355-safety-large-V4.png" alt={tnbcSafetyAlt3}/>}
                                    title="Adverse reactions occurring in ≥20% of patients receiving KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo in KEYNOTE&#8288;-&#8288;355"
                                    footnotes={safetyTable3Footnotes}
                                    definitions="ALT = alanine aminotransferase; AST = aspartate aminotransferase."
                                />
                           
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;